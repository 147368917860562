import { Application } from '@hotwired/stimulus'

import SliderController from './controllers/slider_controller'
import ProductVariantController from './controllers/product_variant_controller'
import ProductController from './controllers/product_controller'
import VisibilityController from './controllers/visibility_controller'

const application = Application.start()

application.register('slider', SliderController)
application.register('product-variant', ProductVariantController)
application.register('product', ProductController)
application.register('visibility', VisibilityController)
